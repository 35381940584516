
import Vue, { PropType } from "vue"
import { type Camera } from "@evercam/shared/types"
import CameraCard from "@evercam/shared/components/CameraCard.vue"

export default Vue.extend({
  name: "CopilotTimelapseCameraSelector",
  components: { CameraCard },
  props: {
    token: {
      type: String,
      default: "",
    },
    cameras: {
      type: Array as PropType<Camera[]>,
      required: true,
    },
    selectedCamera: {
      type: [Object, undefined] as PropType<Camera>,
      default: undefined,
    },
  },
  methods: {},
})
