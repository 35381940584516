
import Vue, { PropType } from "vue"
import {
  type CopilotTimelapsesAvailabilityByPeriod,
  TimelapseType,
} from "@evercam/shared/types"
import moment from "moment-timezone"
import type { HeatmapBarItem } from "@evercam/ui"

type AvailabilityMap = {
  [year: string | number]: {
    [month: string | number]: {
      [day: string | number]: number[]
    }
  }
}

type HeatmapConfig = {
  label: string
  type: string
  items: HeatmapBarItem[]
  selectedValue?: unknown
}

enum Transition {
  Left = "slide-left",
  Right = "slide-right",
}

export default Vue.extend({
  name: "CopilotTimelapseVideoSelector",
  props: {
    timelapses: {
      type: Object as PropType<CopilotTimelapsesAvailabilityByPeriod>,
      default: () =>
        ({
          month: [],
          week: [],
          day: [],
          hour: [],
        } as CopilotTimelapsesAvailabilityByPeriod),
    },
  },
  data() {
    return {
      isFirstLoad: true,
      selectedYear: moment().year() as number,
      selectedMonth: (moment().month() + 1) as number,
      selectedWeek: moment().week() as number,
      selectedDay: moment().date() as number,
      selectedHour: moment().hour() as number,
      weeksDaysTransitionName: Transition.Left,
      hoursTransitionName: Transition.Left,
      selectedTimelapseId: 0,
      selectedTimelapseType: undefined as unknown as TimelapseType,
    }
  },
  computed: {
    TimelapseType() {
      return TimelapseType
    },
    heatmapBars(): Record<string, HeatmapConfig> {
      return {
        month: {
          label: "Month",
          type: TimelapseType.Month,
          items: this.months,
          selectedValue: this.selectedMonth,
        },
        week: {
          label: "Week",
          type: TimelapseType.Week,
          items: this.weeks,
          selectedValue: this.selectedWeek,
        },
        day: {
          label: "Day",
          type: TimelapseType.Day,
          items: this.days,
          selectedValue: this.selectedDay,
        },
        hour: {
          label: "Hour",
          type: TimelapseType.Hour,
          items: this.hours,
          selectedValue: this.selectedHour,
        },
      }
    },
    months(): HeatmapBarItem[] {
      const yearData = this.availabilityMap[this.selectedYear]
      if (!yearData) return []

      return Array.from({ length: 12 }, (_, i) => {
        const month = i + 1
        const monthMoment = moment()
          .year(this.selectedYear as number)
          .month(i)
        const monthKey = monthMoment.format("YYYY-MM")
        const hasChildren = this.doesMonthHaveChildren(month)
        const hasTimelapse = this.timelapsesIdsByPeriod[monthKey]

        return {
          name: monthMoment.format("MMM"),
          value: month,
          count: hasTimelapse ? 1 : 0,
          itemClass: {
            "has-children": hasChildren,
            "has-timelapse": hasTimelapse,
          },
          id: monthKey,
        }
      }) as unknown as HeatmapBarItem[]
    },
    weeks(): HeatmapBarItem[] {
      const yearData = this.availabilityMap[this.selectedYear]
      if (!yearData) return []

      const startOfMonth = moment()
        .year(this.selectedYear)
        .month(this.selectedMonth - 1)
        .startOf("month")
      const endOfMonth = startOfMonth.clone().endOf("month")
      const weeks: HeatmapBarItem[] = []

      let currentWeek = startOfMonth.clone().startOf("week").days(0)
      while (currentWeek.isBefore(endOfMonth)) {
        const weekNumber = currentWeek.week()
        let weekKey = "W" + currentWeek.clone().format("YYYY-MM-DD")
        const weekKey2 =
          "W" + currentWeek.clone().add(1, "day").format("YYYY-MM-DD")
        if (this.timelapsesIdsByPeriod[weekKey2]) {
          weekKey = weekKey2
        }
        const weekStart = currentWeek.clone().format("MMM Do")
        const weekEnd = currentWeek.clone().add(6, "days").format("MMM Do")
        const hasChildren = this.timelapsesIdsByPeriod[weekKey]

        weeks.push({
          name: `W${weekNumber}: ${weekStart} - ${weekEnd}`,
          value: weekNumber,
          count: hasChildren ? 1 : 0,
          itemClass: hasChildren ? "has-children has-timelapse week" : "",
          id: weekKey,
        })

        currentWeek.add(1, "week")
      }

      return weeks
    },
    days(): HeatmapBarItem[] {
      const yearData = this.availabilityMap[this.selectedYear]
      if (!yearData || !yearData[this.selectedMonth]) return []

      const month = moment()
        .year(this.selectedYear)
        .month(this.selectedMonth - 1)
      const daysInMonth = month.daysInMonth()

      return Array.from({ length: daysInMonth }, (_, i) => {
        const day = i + 1
        const dayKey = month.clone().date(day).format("YYYY-MM-DD")
        const hasTimelapse = this.timelapsesIdsByPeriod[dayKey]
        const hasChildren = this.doesDayHaveChildren(day) || hasTimelapse

        return {
          name: month.clone().date(day).format("Do"),
          value: day,
          count: hasTimelapse ? 1 : 0,
          itemClass: {
            "has-children": hasChildren,
            "has-timelapse": hasTimelapse,
          },
          id: dayKey,
        }
      }) as unknown as HeatmapBarItem[]
    },
    hours(): HeatmapBarItem[] {
      const day = moment()
        .year(this.selectedYear)
        .month(this.selectedMonth - 1)
        .date(this.selectedDay || 1)

      return Array.from({ length: 24 }, (_, i) => {
        const hourKey = day.clone().hour(i).format("YYYY-MM-DDTHH")
        const hasTimelapse = this.timelapsesIdsByPeriod[hourKey]

        return {
          name: day.clone().hour(i).format("ha"),
          value: i,
          count: hasTimelapse ? 1 : 0,
          itemClass: hasTimelapse ? "has-children has-timelapse" : "",
          id: hourKey,
        }
      })
    },
    timelapsesIdsByPeriod(): Record<string, number> {
      if (!this.timelapses) {
        return {}
      }
      const transformedMap = {} as Record<string, number>

      Object.entries(this.timelapses).forEach(([period, entries]) => {
        entries.forEach((entry) => {
          const date = moment(entry.timestamp)
          let formattedDate = ""

          switch (period) {
            case TimelapseType.Month:
              formattedDate = date.format("YYYY-MM")
              break
            case TimelapseType.Week:
              formattedDate = "W" + date.format("YYYY-MM-DD")
              break
            case TimelapseType.Day:
              formattedDate = date.format("YYYY-MM-DD")
              break
            case TimelapseType.Hour:
              formattedDate = date.format("YYYY-MM-DDTHH")
              break
          }

          transformedMap[formattedDate] = entry.id
        })
      })

      return transformedMap
    },
    availabilityMap(): AvailabilityMap {
      const map: AvailabilityMap = {}

      const addToMap = (date: moment.Moment, type: TimelapseType) => {
        const year = date.year()
        const month = date.month() + 1
        const day = date.date()
        const hour = date.hour()

        if (!map[year]) {
          map[year] = {}
        }

        if (!map[year][month]) {
          map[year][month] = {}
        }

        if (type === TimelapseType.Day || type === TimelapseType.Hour) {
          if (!map[year][month][day]) {
            map[year][month][day] = []
          }
        }

        if (type === TimelapseType.Hour) {
          if (!map[year][month][day].includes(hour)) {
            map[year][month][day].push(hour)
          }
        }
      }

      Object.entries(this.timelapses).forEach(([period, items]) => {
        items.forEach((item) => {
          const date = moment(item.timestamp)
          addToMap(date, period as TimelapseType)
        })
      })

      return map
    },
  },
  watch: {
    selectedTimelapseId(id: number) {
      this.$emit("change", id)
    },
    selectedMonth(newValue, oldValue) {
      if (this.isFirstLoad) {
        this.isFirstLoad = false

        return
      }
      this.weeksDaysTransitionName =
        newValue > oldValue ? Transition.Left : Transition.Right
      this.hoursTransitionName = this.weeksDaysTransitionName
    },
    selectedDay(newValue, oldValue) {
      if (this.isFirstLoad) {
        this.isFirstLoad = false

        return
      }
      this.hoursTransitionName =
        newValue > oldValue ? Transition.Left : Transition.Right
    },
    timelapses: {
      handler() {
        this.selectLatestAvailableTimelapse()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    selectLatestAvailableTimelapse() {
      const latestTimelapse = this.findLatestTimelapse()
      if (latestTimelapse) {
        const date = moment(latestTimelapse.timestamp)
        this.selectedYear = date.year()
        this.selectedMonth = date.month() + 1
        this.selectedWeek = date.week()
        this.selectedDay = date.date()
        this.selectedHour = date.hour()
        this.selectedTimelapseType = latestTimelapse.type
        this.selectedTimelapseId = latestTimelapse.id
      }
    },

    findLatestTimelapse() {
      let latestTimelapse = null
      let latestTimestamp = null

      Object.entries(this.timelapses).forEach(([period, entries]) => {
        entries.forEach((entry) => {
          const timestamp = moment(entry.timestamp)
          if (!latestTimestamp || timestamp.isAfter(latestTimestamp)) {
            latestTimestamp = timestamp
            latestTimelapse = { ...entry, type: period as TimelapseType }
          }
        })
      })

      return latestTimelapse
    },
    doesMonthHaveChildren(month: number): boolean {
      const yearData = this.availabilityMap[this.selectedYear]
      if (!yearData) return false

      const monthData = yearData[month]
      if (!monthData) return false

      return Object.keys(monthData).length > 0
    },
    doesDayHaveChildren(dayNumber: number): boolean {
      const yearData = this.availabilityMap[this.selectedYear]
      if (!yearData) return false

      const monthData = yearData[this.selectedMonth]
      if (!monthData) return false

      const dayData = monthData[dayNumber]

      return dayData && dayData.length > 0
    },
    handleBarSelection(
      type: TimelapseType,
      value: number | string,
      itemClass: string,
      id: string | number
    ) {
      if (!this.isSelectable(itemClass)) {
        return
      }

      const timelapseId = this.timelapsesIdsByPeriod[id]
      if (timelapseId) {
        this.selectedTimelapseType = type
        this.selectedTimelapseId = timelapseId
      }

      switch (type) {
        case TimelapseType.Month:
          this.selectedMonth = value as number
          this.selectedDay = this.findFirstSelectable(TimelapseType.Day)
            ?.value as number
          this.selectedHour = this.findFirstSelectable(TimelapseType.Hour)
            ?.value as number
          break
        case TimelapseType.Week:
          this.selectedWeek = value as number
          break
        case TimelapseType.Day:
          this.selectedDay = value as number
          this.selectedHour = this.findFirstSelectable(TimelapseType.Hour)
            ?.value as number
          break
        case TimelapseType.Hour:
          this.selectedHour = value as number
          break
      }
    },
    isSelectable(itemClass: string | Record<string, boolean>) {
      return (
        (typeof itemClass === "string" &&
          (itemClass.includes("has-children") ||
            itemClass.includes("has-timelapse"))) ||
        (typeof itemClass === "object" &&
          (itemClass["has-children"] || itemClass["has-timelapse"]))
      )
    },
    findFirstSelectable(type: TimelapseType): HeatmapBarItem {
      return this.heatmapBars[type].items.find((i) =>
        this.isSelectable(i.itemClass!)
      ) as HeatmapBarItem
    },
  },
})
