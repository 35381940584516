
import Vue, { PropType } from "vue"
import type { Camera } from "@evercam/shared/types"
import CameraStatusIcon from "@evercam/shared/components/CameraStatusIcon"
import SnapshotCard from "@evercam/shared/components/SnapshotCard"

export default Vue.extend({
  name: "CameraCard",
  components: { SnapshotCard, CameraStatusIcon },
  props: {
    camera: {
      type: Object as PropType<Camera>,
      required: true,
    },
    targetTimestamp: {
      type: [String],
      default: undefined,
    },
    token: {
      type: String,
      required: true,
    },
    absolute: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 100,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    resize: {
      type: Boolean,
      default: true,
    },
    labelClass: {
      type: String,
      default: "",
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9,
    },
    showThumbnail: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "",
    },
    chip: {
      type: Boolean,
      default: false,
    },
    showExid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      previousTimestamp: "",
    }
  },
  computed: {
    snapshotTooltipItem() {
      return {
        label: this.camera.name,
        thumbnailUrl: this.getThumbnailUrl(this.targetTimestamp),
        placeholderThumbnailUrl: this.getThumbnailUrl(this.previousTimestamp),
        fallbackThumbnailUrl: this.getThumbnailUrl(),
      }
    },
  },
  watch: {
    targetTimestamp(_, previousTimestamp) {
      this.previousTimestamp = previousTimestamp
    },
  },
  methods: {
    getThumbnailUrl(timestamp?: string): string {
      const baseUrl = this.$config.public.apiURL
      const queryParams = `authorization=${this.token}&view=true`

      if (!timestamp) {
        return `${baseUrl}/cameras/${this.camera.exid}/thumbnail?${queryParams}`
      }

      const t = new Date(
        Math.max(
          new Date(this.camera.createdAt).getTime(),
          new Date(timestamp).getTime()
        )
      ).toISOString()

      return `${baseUrl}/cameras/${this.camera.exid}/recordings/snapshots/${t}/nearest?${queryParams}`
    },
  },
})
