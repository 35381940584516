
import Vue, { PropType } from "vue"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { TimelinePlayerTooltipItem } from "@/types/index.ts"

export default Vue.extend({
  name: "SnapshotCard",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    item: {
      type: Object as PropType<TimelinePlayerTooltipItem>,
      required: true,
    },
    absolute: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 120,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    resize: {
      type: Boolean,
      default: true,
    },
    labelClass: {
      type: String,
      default: "",
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9,
    },
    showSnapshot: {
      type: Boolean,
      default: true,
    },
    chip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isError: false,
      isLoading: true,
      retries: 0,
      thumbnailUrl: "",
    }
  },
  computed: {
    tooltipWidthStyle(): Record<string, any> {
      if (this.chip) {
        return {}
      }

      return {
        width: `${this.width}px`,
      }
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(current, previous) {
        if (current.thumbnailUrl === previous?.thumbnailUrl) {
          return
        }
        this.thumbnailUrl = this.getResizedUrl(current.thumbnailUrl)
        this.isLoading = true
        this.isError = false
      },
    },
  },
  methods: {
    onClick() {
      this.$emit("click", this.item)
    },
    getResizedUrl(url: string): string {
      if (!url) {
        return ""
      }

      if (!this.resize) {
        return url
      }

      return this.$imgproxy.get360pResizedImageUrl(url)
    },
    onError() {
      console.log("Error loading image", this.item.thumbnailUrl, this.retries)
      this.isError = true
      this.thumbnailUrl = this.item.fallbackThumbnailUrl
    },
    onLoad() {
      this.$nextTick(() => {
        this.isError = false
        this.isLoading = false
      })
    },
  },
})
