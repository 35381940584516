
import Vue from "vue"
import { mapStores } from "pinia"
import { MetaInfo } from "vue-meta"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { useLayoutStore } from "@/stores/layout"
import { useProjectStore } from "@/stores/project"
import { Camera, Project, AnalyticsEventPageId } from "@evercam/shared/types"
import CopilotTimelapseChat from "@evercam/shared/components/copilot/CopilotTimelapseChat.vue"

export default Vue.extend({
  name: "CopilotTimelapses",
  components: {
    CopilotTimelapseChat,
  },
  middleware({ redirect, $permissions }) {
    const shouldRedirect = !(
      $permissions.user.can.access.copilot() &&
      $permissions.project.has.copilotTimelapseReports()
    )

    if (shouldRedirect) {
      return redirect("/v2/projects")
    }
  },
  meta: {
    pageId: AnalyticsEventPageId.Copilot,
  },
  data() {
    return {
      selectedCamera: null as unknown as Camera,
    }
  },
  head(): MetaInfo {
    let classList: string[] = []
    if (this.$vuetify.breakpoint.mdAndUp) {
      classList.push("overflow-y-hidden")
    }

    return {
      title: `${this.projectStore.selectedProject?.name} | ${this.$t(
        "pages.copilot"
      )}`,
      meta: [
        { charset: "utf-8" },
        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
      htmlAttrs: {
        class: classList.join(" "),
      },
    } as unknown as MetaInfo
  },
  computed: {
    ...mapStores(
      useCameraStore,
      useAccountStore,
      useLayoutStore,
      useProjectStore
    ),
    containerStyles(): Record<string, string> {
      return {
        height: `${this.playerHeight}px`,
        maxHeight: `${this.playerHeight}px`,
        width: `${this.playerWidth}px`,
      }
    },
    selectedProject(): Project {
      return this.projectStore.selectedProject as Project
    },
    playerHeight(): number {
      return this.layoutStore.mainContentRect.height
    },
    playerWidth(): number {
      return this.layoutStore.mainContentRect.width
    },
  },
  mounted() {
    if (!this.$permissions.user.is.admin()) {
      this.$posthog.startSessionRecording()
    }
    this.layoutStore.toggleIsLeftSidebarPersistent()
    if (!this.layoutStore.isLeftSidebarMinimized) {
      this.layoutStore.toggleIsLeftSidebarMinimized()
    }
  },
  destroyed() {
    if (!this.$permissions.user.is.admin()) {
      this.$posthog.stopSessionRecording()
    }
    this.layoutStore.toggleIsLeftSidebarPersistent()
    if (this.layoutStore.isLeftSidebarMinimized) {
      this.layoutStore.toggleIsLeftSidebarMinimized()
    }
  },
  methods: {},
})
